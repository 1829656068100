import axios from "axios";

export  default class FormHelper {
    static initForms(){
        document.querySelectorAll('[data-ajax]').forEach((frm) => {
            FormHelper.init(frm);
        });
    }
    static init(frm){
        frm.addEventListener('submit', (e) => {
            e.preventDefault();
            let data = new FormData(frm);
            let url = e.currentTarget.action;
            let successPlaceholder = frm.querySelector('.txt-success-send');
            let errorPlaceholder = frm.querySelector('.txt-error-send');
            successPlaceholder.classList.add('d-none');
            errorPlaceholder.classList.add('d-none');
            axios({
                method:'post',
                url:url,
                data:data
            }).then((response) => {
                if (response.data.result === 'ok'){
                    successPlaceholder.innerHTML = response.data.message;
                    frm.reset();
                    successPlaceholder.classList.remove('d-none');
                    errorPlaceholder.classList.add('d-none');
                }
                else {
                    successPlaceholder.innerHTML = response.data.message;
                    successPlaceholder.classList.add('d-none');
                    errorPlaceholder.classList.remove('d-none');
                }
            });
        });
    }
}
